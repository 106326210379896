const CODE_VEGETARIAN = 'veggie';
const CODE_VEGAN = 'vegan';
const CODE_SPICE = 'spice';
const CODE_SALE = 'sale';
const CODE_NEW = 'new';
const CODE_HALAL = 'halal';
const CODE_HIT = 'bestseller';
const CODE_ALCOHOL = 'alcohol';

export const isVegeterian = types => {
  return Boolean(
    types.find(type => type.code === CODE_VEGETARIAN || type.name === 'Вегетарианская'),
  );
};

export const isVegan = types => {
  return Boolean(types.find(type => type.code === CODE_VEGAN || type.name === 'Веган'));
};

export const isHot = types => {
  return Boolean(types.find(type => type.code === CODE_SPICE || type.name === 'Острая'));
};

export const isSale = types => {
  return Boolean(types.find(type => type.code === CODE_SALE || type.name === 'Скидка'));
};

export const isNew = types => {
  return Boolean(types.find(type => type.code === CODE_NEW || type.name === 'Новинка'));
};

export const isHalal = types => {
  return Boolean(types.find(type => type.code === CODE_HALAL || type.name === 'Халяль'));
};

export const isHit = types => {
  return Boolean(types.find(type => type.code === CODE_HIT || type.name === 'Хит'));
};

export const isAlcohol = types => {
  return Boolean(types.find(type => type.code === CODE_ALCOHOL || type.name === 'Алкоголь'));
};

export const isPizza = variation => {
  return Boolean(variation && variation.kind);
};
