import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

// Selectors
import {isThemeHalloween, isThemeNewYear} from "../../../state/modules/city/selectors";

// Components
import Checkbox from '../../CheckboxField';

// Icons
import Done from '../../../icons/done.svg';
import Plus from '../../../icons/plus.svg';

// Style
import styles from './Crust.styl';

import { CRUST_EXTRA, CRUST_EXTRA_EN } from '../../../utils/constants';
import { sendEventViewProduct } from '../../../utils/metrics';

const cx = classNames.bind(styles);

export default function Crust(props) {
  const { isOpen, variation, resetCrust, selectedCrust, setSelectedCrust, specialStylesMode } = props;
  const isPhone = useSelector(state => state.responsive.isPhone);
  const isHalloweenMode = useSelector(isThemeHalloween);
  const isNewYearMode = useSelector(isThemeNewYear);
  const crustList = variation.available_ingredients?.filter(
    item => item.type === CRUST_EXTRA || item.type === CRUST_EXTRA_EN,
  );

  useEffect(() => {
    resetCrust.reset = () => {
      setSelectedCrust(null);
    };
  }, []);

  return (
    <div className={cx('Crust', {
      'Crust__modal': isOpen,
      'Crust__halloween': isHalloweenMode && specialStylesMode,
      'Crust__new-year': isNewYearMode && specialStylesMode,
    })} data-test-id="crust">
      {crustList
        ? crustList.map(item => {
          return (
            <div className={cx('Crust__item', { 'Crust__item-modal': isOpen })} key={item.id}>
              {!isPhone ? (
                selectedCrust?.id === item.id ? (
                  <Done
                    className={cx('Crust__done')}
                    color="#50A684"
                    width={14}
                    height={16}
                    onClick={() => {
                      sendEventViewProduct();
                      setSelectedCrust(null);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <Plus
                    className={cx('Crust__plus')}
                    fill="#50A684"
                    width={14}
                    height={14}
                    onClick={() => {
                      sendEventViewProduct();
                      setSelectedCrust(item);
                    }}
                    style={{ cursor: 'pointer' }}
                  />
                )
              ) : (
                <Checkbox
                  onChange={() => {
                    sendEventViewProduct();
                    if (selectedCrust?.id !== item.id) {
                      setSelectedCrust(item);
                    } else {
                      setSelectedCrust(null);
                    }
                  }}
                  value={selectedCrust?.id === item.id}
                />
              )}
              <div className={cx('Crust__text', 'Crust__name', { 'Crust__text-modal': isOpen })}>{item.name}</div>
              <div className={cx('Crust__text', { 'Crust__price-modal': isOpen })}>{`${item.price} ₽`}</div>
            </div>
          );
        })
        : null}
    </div>
  );
}

Crust.defaultProps = {
  className: '',
};

Crust.propTypes = {
  isOpen: PropTypes.bool,
  className: PropTypes.string,
  variation: PropTypes.object.isRequired,
  resetCrust: PropTypes.object.isRequired,
};
