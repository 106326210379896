import * as types from './actionTypes';
import constructorApi from './api';

/**
 * Подтягиваем данные по пицце
 *
 * @param {string} id id пиццы
 */
export const fetchGood = id => {
  return dispatch => {
    dispatch({ type: types.FETCH_GOOD_REQUEST, payload: { id } });

    return constructorApi
      .getCatalogGood(id)
      .then(data => {
        return dispatch({ type: types.FETCH_GOOD_SUCCESS, payload: { id, data } });
      })
      .catch(({ message }) => {
        return dispatch({ type: types.FETCH_GOOD_FAILURE, payload: { id, error: message } });
      });
  };
};

/**
 * Сбрасываем значения после успешного добавления в корзину
 *
 * @param {string} id id пиццы
 */
export const setIsAdded = id => ({
  type: types.SET_IS_ADDED,
  payload: id,
});

/**
 * Задает кастомное имя пицце
 *
 * @param {string} id id пиццы
 * @param {string} name кастомное имя
 */
export const setCustomName = (id, name) => ({
  type: types.SET_CUSTOM_NAME,
  payload: { id, name },
});

/**
 * Меняет тип теста
 *
 * @param {string} id id пиццы
 * @param {string} dough выбранный тип теста
 */
export const changeDough = (id, dough) => ({
  type: types.CHANGE_DOUGH,
  payload: { id, dough },
});

/**
 * Меняет размер пиццы
 *
 * @param {string} id id пиццы
 * @param {string} size выбранный размер
 */
export const changeSize = (id, size) => ({
  type: types.CHANGE_SIZE,
  payload: { id, size },
});

/**
 * Перключает сырный борт
 *
 * @param {string} id id пиццы
 * @param {number} value 0 - не выбран, 1 - выбран
 */
export const toggleStuffedCrust = (id, value) => ({
  type: types.TOGGLE_STUFFED_CRUST,
  payload: { id, value },
});

/**
 * Выбрать борт
 *
 * @param {string} id id пиццы
 * @param {number} value
 */
export const selectStuffedCrust = (id, value) => ({
  type: types.SELECT_STUFFED_CRUST,
  payload: { id, value },
});

/**
 * Поменять текущую пиццу
 *
 * @param {string} id id пиццы
 * @param {object} variations
 */
export const changeAvailableVariations = (id, variations) => ({
  type: types.CHANGE_AVAILABLE_VARIATIONS,
  payload: { id, variations },
});

/**
 * Увеличивает кол-во порции ингредиента
 *
 * @param {string} id id пиццы
 * @param {string} variationId id выбранной вариации
 * @param {string} ingredientId id ингредиента
 * @param {bool} included ингредиент по-умолчанию?
 */
export const addPortion = (id, variationId, ingredientId, included) => ({
  type: types.ADD_PORTION,
  payload: { id, variationId, ingredientId, included },
});

/**
 * Уменьшает кол-во порции ингредиента
 *
 * @param {string} id id пиццы
 * @param {string} variationId id выбранной вариации
 * @param {string} ingredientId id ингредиента
 * @param {bool} included ингредиент по-умолчанию?
 */
export const removePortion = (id, variationId, ingredientId, included) => ({
  type: types.REMOVE_PORTION,
  payload: { id, variationId, ingredientId, included },
});

/**
 * Удаляет ингредиент полностью,т.е удаляется любое кол-во порций
 *
 * @param {string} id id пиццы
 * @param {string} variationId id выбранной вариации
 * @param {string} ingredientId id ингредиента
 * @param {bool} included ингредиент по-умолчанию?
 */
export const removeIngredient = (id, variationId, ingredientId, included) => ({
  type: types.REMOVE_INGREDIENT,
  payload: { id, variationId, ingredientId, included },
});

/**
 * Сбрасываем значения после успешного добавления в корзину
 *
 * @param {string} id id пиццы
 */
export const reset = id => ({
  type: types.RESET,
  payload: { id },
});
