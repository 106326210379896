import { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// State
import { fetchGiftsIsNeeded } from '../../state/modules/gifts/actions';
import { addCartItem } from '../../state/modules/cart/actions';
import { setGlobalMessage } from '../../utils/setGlobalMessage';

export default function useGiftsSlider() {
  const dispatch = useDispatch();
  const list = useSelector(state => state.gifts?.list);
  const stockCode = useSelector(state => state.stock?.data?.code);
  const [isLoading, setIsLoading] = useState([]);
  const compositionCart = useSelector(state => state.cart?.composition);

  const onAddClick = useCallback(async gift => {
    setIsLoading(prev => [...prev, gift.sku]);
    await dispatch(addCartItem({ composition: [{ type: 'bonus', item: gift.item }] }));
    setIsLoading(prev => prev.filter(prevSku => prevSku !== gift.sku));
  }, []);

  const isDisabled = useMemo(() => !!stockCode, [stockCode]);

  useEffect(() => {
    dispatch(fetchGiftsIsNeeded());
  }, []);

  return { list, isLoading, onAddClick, isDisabled };
}
