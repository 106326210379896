import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Transition } from 'react-transition-group';

// Styles
import styles from './Flipper.styl';

const cx = classNames.bind(styles);

export default function Flipper(props) {
  const { className, backside, children, isFlipped } = props;

  return (
    <Transition in={isFlipped} timeout={800}>
      {state => (
        <div className={cx('Flipper', className)}>
          <div className={cx('Flipper__front', `Flipper__front_${state}`)} data-test-id='flipper_font'>{children}</div>
          {backside && (
            <div className={cx('Flipper__back', `Flipper__back_${state}`)}>{backside}</div>
          )}
        </div>
      )}
    </Transition>
  );
}

Flipper.defaultProps = {
  className: '',
  backside: null,
};

Flipper.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isFlipped: PropTypes.bool.isRequired,
  backside: PropTypes.node,
};
