import React, { useEffect, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Transition } from 'react-transition-group';
import _debounce from 'lodash/debounce';

// Utils
import { disableBodyScroll, clearAllBodyScrollLocks } from '../../../utils/body-scroll-lock';

// Styles
import styles from './Modal.styl';

const cx = classNames.bind(styles);

const modalRoot = typeof window !== 'undefined' ? document.getElementById('modal-root') : null;

export default function Modal(props) {
  const { className, children, isOpen, onClose, changeFormHeight } = props;

  const isHandleScrollTo = useRef(false);
  const overlayRef = useRef(null);
  const contentRef = useRef(null);
  const emptyRef = useRef(null);

  const handleScrollTo = () => {
    overlayRef.current.scrollTo(0, contentRef.current.offsetTop - 25);
    isHandleScrollTo.current = true;
  };

  const handleScrollToDebounced = useCallback(
    _debounce(e => handleScrollTo(e), 150),
    [],
  );

  const handleScroll = () => {
    if (isHandleScrollTo.current) {
      isHandleScrollTo.current = false;

      return;
    }

    const contentRect = contentRef.current.getBoundingClientRect();

    // начинаем расчеты только когда видно верхнюю границу контента
    if (contentRect.y >= 0) {
      const emptyRect = emptyRef.current.getBoundingClientRect();

      if (contentRect.y >= emptyRect.height) {
        onClose();
        handleScrollToDebounced.cancel();
      } else {
        handleScrollToDebounced();
      }
    }
  };

  const handleScrollDebounced = useCallback(
    _debounce(e => handleScroll(e), 10),
    [],
  );

  useEffect(() => {
    if (isOpen) {
      disableBodyScroll(overlayRef.current);
      handleScrollTo();
      overlayRef.current.addEventListener('scroll', handleScrollDebounced, { passive: true });
    }

    return () => {
      if (isOpen && overlayRef.current) {
        clearAllBodyScrollLocks();
        overlayRef.current.removeEventListener('scroll', handleScrollDebounced);
      }
    };
  }, [isOpen]);

  useEffect(() => {
    if (contentRef.current !== null) {
      handleScrollTo();
    }
  }, [changeFormHeight]);

  return (
    modalRoot &&
    createPortal(
      <Transition in={isOpen} timeout={{ enter: 0, exit: 700 }} mountOnEnter unmountOnExit>
        {state => (
          <>
            <div className={cx('ModalPhone__overlay', `ModalPhone__overlay_${state}`)} />
            <div className={cx('ModalPhone', `ModalPhone_${state}`)} ref={overlayRef} id="modal">
              <button
                className={cx('ModalPhone__empty')}
                onClick={onClose}
                type="button"
                ref={emptyRef}
              />
              <div className={cx('ModalPhone__content', className)} ref={contentRef}>
                {children}
                <div className={cx('ModalPhone__padding')} />
              </div>
            </div>
          </>
        )}
      </Transition>,
      modalRoot,
    )
  );
}

Modal.defaultProps = {
  className: '',
  isOpen: false,
  onClose: () => null,
  children: null,
  changeFormHeight: null,
};

Modal.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
  changeFormHeight: PropTypes.any,
};
