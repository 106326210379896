import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import Heading from '../../Heading';
import Button from '../../Button';

import { toggleAlcoholWarning } from '../../../state/modules/ui/actions';

// Style
import styles from './AlcoholWarning.styl';

const cx = classNames.bind(styles);

export default function AlcoholWarning(props) {
  const { className } = props;

  const dispatch = useDispatch();
  const isAlcoholWarningOpen = useSelector(state => state.ui.isAlcoholWarningOpen);

  const handleClick = () => {
    dispatch(toggleAlcoholWarning());
  };

  if (!isAlcoholWarningOpen) {
    return null;
  }

  return (
    <div className={cx('AlcoholWarning', className)}>
      <Heading className={cx('AlcoholWarning__title')} level={3} tagName="div">
        <FormattedMessage id="productCard.alcoholwarning.title" />
      </Heading>
      <Button className={cx('AlcoholWarning__button')} onClick={handleClick}>
        <FormattedMessage id="productCard.alcoholwarning.button" />
      </Button>
    </div>
  );
}

AlcoholWarning.defaultProps = {
  className: '',
};

AlcoholWarning.propTypes = {
  className: PropTypes.string,
};
